import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../images/bergson-marca.png";
import { i18n } from "../translate/i18n";

export default function WhatsApp() {
  return (
    <FloatingWhatsApp
      phoneNumber="553135711370"
      avatar={logo}
      statusMessage="on-line"
      chatMessage={i18n.t("whatsapp.chatMessage")}
      accountName="Bergson"
      darkMode={true}
      allowEsc={true}
    />
  );
}
