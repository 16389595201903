const messages = {
  en: {
    translations: {
      menu_titles: {
        main_menu: "MAIN MENU",
        about_us: "ABOUT US",
        contact: "CONTACT",
        our_companies: "OUR COMPANIES",
      },
      main_menu: {
        titles: {
          title1:
            "If you are looking for a reliable and quality-committed company, do not hesitate to contact us!",
          title2:
            "We are ready to meet your demands and provide solutions that will make a difference!",
          title3: "Some of our main clients!",
        },
        messages: {
          message1:
            "Bergson Corporation was recently created, it is a group that brings together important brands such as: Soluções Industrial, Bergson do Brasil and Soluções Construção. Headquartered in Belo Horizonte/MG, the companies have a wide range of products in the section of cargo equipment, manufacture and maintenance of cargo lifting, PPE materials (personal protection equipment) and in feed distribution.",
          message2:
            "For the company, the result is always the best way to showcase its competence. It is the one that, through practice, serves us with precision. We realize the power of our innovation in the daily operations of the group's companies. Investments in service provision and marketing of industrial supplies are our strengths.",
        },
      },
      about_us: {
        title: "TESTIMONY",
        message:
          "Technological advances and knowledge show us that we are competent in what we deliver, seeking quality and perfection. We exist to ensure the satisfaction of our customers and we seek to invest in training and valuing our employees. We want to make people's lives easier, with aptitude and fullness.",
        depoiments: {
          depoiment1:
            "José Luiz Matos has been working for 11 years at one of the companies that make up the Bergson group, as a developer of electrical and automation projects. “It is with satisfaction and with love that I try to do my best within the group, I see my work as an opportunity to grow… For me it is an honor to belong to this group that is Bergson Cooportarion”.",
        },
        mission: {
          title: "MISSION",
          text: "Our mission is to lead innovation in our industry, developing innovative solutions that solve the challenges of our customers and society. We believe in working collaboratively and creatively, always looking for ways to improve our products, services and processes. We strive to be a responsible and ethical company that contributes to the sustainable development of society.",
        },

        vision: {
          title: "VISION",
          text: "Our vision is to be recognized as a leader in our industry, offering exceptional products and services that exceed our customers' expectations and contribute to the well-being of society and the planet. We want to be a company that is respected and admired by our employees, partners and communities, and we want to work ethically and responsibly to achieve our long-term goals.",
        },

        values: {
          title: "VALUES",
          text: "Respect, Transparency, Ethics, Social Responsibility, Commitment and Innovation.",
        },
      },
      contact: {
        name: "Name",
        cellphone: "Phone number",
        company: "Company Name",
        header: "Message subject",
        body: "Message",
        submit: "SUBMIT",
        address: "Business address",
        phone: "Phone number",
        email: "Send us an email",
      },
      our_companies: {
        industrial_solution:
          "Soluções Industrial, was founded in 2008 in the city of Belo Horizonte, has 15 years of experience in the cargo equipment maintenance market and in the growth of technical projects for hydroelectric plants, whether thermal or nuclear.",
        bergson_brasil:
          "Bergson do Brasil, founded in 1999 in the city of Belo Horizonte, has been operating in the cargo lifting equipment manufacturing market for 24 years. With the creation of technical projects for hydroelectric, thermal and nuclear power plants, cement, sugar and oil industries, among many other segments.",
        see_more: "See more about ->",
      },
      whatsapp: {
        chatMessage: "Hello! How can I help you?",
      },
      modal: {
        title: "Thank you!",
        message: "Your message has been sent and we will contact you shortly!",
      },
      footer: { networks: "Explore our networks." },
    },
  },
};

export { messages };
