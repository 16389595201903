import styled from "styled-components";
import { i18n } from "../translate/i18n";
import Iframe from "react-iframe";
import React, { useState } from "react";

import bergson from "../images/bergson-corp.png";
import brasil_logo from "../images/bergson-do-brasil-logo.png";
import solucao_logo from "../images/solucao-industrial-logo.png";
import construcao_logo from "../images/solucao-construcao-logo.png";
import CompanyBanner from "./CompanyBanner";

import img3 from "../images/industry-engineer.JPG";
import img5 from "../images/engineering-man.jpg";
import img1 from "../images/load-on-the-hook.jpg";
import img4 from "../images/metal-industry-worker.JPG";

export default function MainMenu() {
  return (
    <>
      <InitialImageText>
        <ImgStyle src={img1} alt="hook" />
        <div style={textBoxStyle}>
          <p>{i18n.t("main_menu.messages.message2")}</p>
        </div>
      </InitialImageText>

      <BigBox
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title>{i18n.t("main_menu.titles.title1")}</Title>
        <SubTitle>{i18n.t("main_menu.titles.title2")}</SubTitle>
        <StyledIframe>
          <Iframe
            frameBorder="0"
            allowFullScreen
            styles={{ width: "100%", height: "100%" }}
            url="https://www.youtube.com/embed/mJ_U0A2OFfE"
            id=""
            className="video"
            display="block"
          />
        </StyledIframe>
      </BigBox>
      <Companies>
        <Box>
          <img src={bergson} alt="bergson" />
        </Box>
        <TextBox>
          <p>{i18n.t("main_menu.messages.message1")}</p>
        </TextBox>
      </Companies>
      <BigBox>
        <CompaniesBox>
          <ImgStyle src={img3} alt="engineer" />
          <TextBoxLink>
            <img
              src={brasil_logo}
              alt="brasil"
              style={{ maxWidth: "80px", marginBottom: "10px" }}
            />
            <p>{i18n.t("our_companies.bergson_brasil")}</p>
          </TextBoxLink>
        </CompaniesBox>
        <CompaniesBox>
          <ImgStyle src={img4} alt="metal" />
          <TextBoxLink>
            <img
              src={solucao_logo}
              alt="solucao"
              style={{ maxWidth: "80px", marginBottom: "10px" }}
            />
            <p>{i18n.t("our_companies.industrial_solution")}</p>
          </TextBoxLink>
        </CompaniesBox>
        <CompaniesBox>
          <ImgStyle src={img5} alt="worker" />
          <TextBoxLink>
            <img
              src={construcao_logo}
              alt="construcao"
              style={{ maxWidth: "80px", marginBottom: "10px" }}
            />
            <p>{i18n.t("our_companies.industrial_solution")}</p>
          </TextBoxLink>
        </CompaniesBox>
      </BigBox>
      <ClientBox>
        <Title>{i18n.t("main_menu.titles.title3")}</Title>
        <CompanyBanner />
      </ClientBox>
    </>
  );
}

const ClientBox = styled.div`
  margin-bottom: 50px;

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`;

const CompaniesBox = styled.div`
  max-width: 350px;

  @media (max-width: 450px) {
    max-width: 100%;
  }
`;

const ImgStyle = styled.img`
  max-width: 350px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in-out;

  @media (max-width: 450px) {
    max-width: 100% !important;
  }
`;

const textBoxStyle = {
  height: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#fff",
  textAlign: "justify",
  lineHeight: "1.5",
  padding: "25px",
};

const InitialImageText = styled.div`
  margin: 80px 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    margin: 0px 0;
    flex-direction: column;
  }
`;

const StyledIframe = styled.div`
  height: 480px;
  width: 800px;

  @media (max-width: 450px) {
    height: 250px;
    width: auto;
  }
`;

const TextBoxLink = styled.div`
  background-color: #01122d;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  cursor: pointer;

  &:hover {
    background-color: #0b1c3a;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 20px;
    height: 100%;
  }
`;

const BigBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  margin-bottom: 80px;

  img {
    width: 350px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    margin: 0px;

    img {
      width: 100%;
    }
  }
`;

const Title = styled.div`
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;

  @media (max-width: 450px) {
    font-size: 24px;
  }
`;

const SubTitle = styled.div`
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
  color: #ffffff;
  font-size: 25px;

  @media (max-width: 450px) {
    font-size: 16px;
    text-align: center;
  }
`;

const Companies = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  gap: 20px;

  @media (max-width: 450px) {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
`;

const Box = styled.div`
  width: 100%;

  div {
    width: 100%;
    //text-align: right;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 300px;

    @media (max-width: 800px) {
      height: 300px;
    }

    @media (max-width: 450px) {
      height: 150px;
    }
  }
`;

const TextBox = styled.div`
  width: 100%;
  padding: 30px;
  background-color: #2b5a7f;
  border-radius: 40px;
  text-align: justify;
  font-size: 25px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);

  @media (max-width: 450px) {
    margin-bottom: 30px;
    border-radius: 10px;
  }
`;
