const messages = {
  pt: {
    translations: {
      menu_titles: {
        main_menu: "MENU PRINCIPAL",
        about_us: "SOBRE NÓS",
        contact: "CONTATO",
        our_companies: "NOSSAS EMPRESAS",
      },
      main_menu: {
        titles: {
          title1:
            "Se você busca uma empresa confiável e comprometida com a qualidade, não hesite em nos contatar!",
          title2:
            "Estamos prontos para atender suas demandas e oferecer soluções que vão fazer a diferença!",
          title3: "Alguns dos nossos principais clientes!",
        },
        messages: {
          message1:
            "A Bergson Corporation foi criada recentemente, é um grupo que reúne importantes marcas como: Solução Industrial, Bergson do Brasil e Solução Construção. Com sede em Belo Horizonte/MG, as empresas possuem uma gama ampla de produtos na seção de equipamentos de carga, fabricação e manutenção de elevação de carga, materiais de EPI (equipamento de proteção individual) e na distribuição de ração.",
          message2:
            "Para a empresa, o resultado é sempre a melhor forma de mostrar a sua competência. É aquela que, com a prática, nos serve com precisão. Sabemos o poder da nossa inovação no dia a dia das empresas do grupo. Os investimentos na prestação de serviço e comercialização de insumos na área industrial, é o nosso forte.",
        },
      },
      about_us: {
        title: "DEPOIMENTOS",
        message:
          "O avanço tecnológico e o conhecimento nos mostram que somos competentes no que entregamos, buscando qualidade e perfeição. Existimos para garantir a satisfação de nossos clientes e procuramos investir na capacitação e na valorização de nossos colaboradores. Queremos facilitar a vida das pessoas, com aptidão e plenitude.",
        depoiments: {
          depoiment1:
            "José Luiz Matos, trabalha a 11 anos em uma das empresas que fazem parte do grupo Bergson, como desenvolvedor de projetos elétricos e de automação. “É com satisfação e com amor que eu procuro fazer o meu melhor dentro do grupo, vejo o meu trabalho como uma oportunidade de crescer… Para mim é uma honra pertencer a esse grupo que é a Bergson Cooportarion”.",
        },
        mission: {
          title: "MISSÃO",
          text: "Nossa missão é liderar a inovação em nossa indústria, desenvolvendo soluções inovadoras que resolvam os desafios dos nossos clientes e da sociedade. Acreditamos em trabalhar de forma colaborativa e criativa, sempre buscando maneiras de melhorar nossos produtos, serviços e processos. Nos esforçamos para ser uma empresa responsável e ética, que contribua para o desenvolvimento sustentável da sociedade.",
        },
        vision: {
          title: "VISÃO",
          text: "Nossa visão é ser reconhecidos como líderes em nossa indústria, oferecendo produtos e serviços excepcionais que superem as expectativas dos nossos clientes e contribuam para o bem-estar da sociedade e do planeta. Queremos ser uma empresa respeitada e admirada por nossos colaboradores, parceiros e comunidades, e trabalhar de forma ética e responsável para alcançar nossos objetivos a longo prazo.",
        },
        values: {
          title: "VALORES",
          text: "Respeito, Transparência, Ética , Responsabilidade social, Comprometimento e Inovação.",
        },
      },
      contact: {
        name: "Nome",
        cellphone: "Número de telefone",
        company: "Nome da empresa",
        header: "Assunto da mensagem",
        body: "Mensagem",
        submit: "ENVIAR",
        address: "Endereço comercial",
        phone: "Telefone",
        email: "Mande-nos um e-mail",
      },
      our_companies: {
        industrial_solution:
          "A Solução Industrial, foi fundada em 2008 na cidade de Belo Horizonte, tem 15 anos de atuação no mercado de manutenção de equipamentos de carga e no crescimento de projetos técnicos para usinas hidrelétricas, seja elas térmicas e nucleares.",
        bergson_brasil:
          "A Bergson do Brasil, foi fundada em 1999 na cidade de Belo Horizonte, tem 24 anos de atuação no mercado de fabricação de equipamentos de elevação de carga. Com a criação de projetos técnicos para as usinas hidrelétricas, térmicas e nucleares, indústrias cimenteiras, açucareiras, petrolíferas, dentre vários outros segmentos.",
        see_more: "Veja mais sobre ->",
      },
      whatsapp: {
        chatMessage: "Olá! Como eu posso te ajudar?",
      },
      modal: {
        title: "Obrigado!",
        message: "Sua mensagem foi enviada e logo entraremos em contato!",
      },
      footer: { networks: "Conheça nossas redes:" },
    },
  },
};

export { messages };
