const messages = {
  es: {
    translations: {
      menu_titles: {
        main_menu: "MENÚ PRINCIPAL",
        about_us: "SOBRE NOSOTROS",
        contact: "CONTACTO",
        our_companies: "NUESTRAS EMPRESAS",
      },
      main_menu: {
        titles: {
          title1:
            "Si estás buscando una empresa confiable y comprometida con la calidad, ¡no dudes en contactarnos!",
          title2:
            "¡Estamos listos para atender tus demandas y ofrecer soluciones que marcarán la diferencia!",
          title3: "¡Algunos de nuestros principales clientes!",
        },
        messages: {
          message1:
            "Bergson Corporation fue creada recientemente, es un grupo que reúne importantes marcas como: Soluções Industrial, Bergson do Brasil y Soluções Construção. Con sede en Belo Horizonte/MG, las empresas tienen una amplia gama de productos en la sección de equipos de carga, fabricación y mantenimiento de izaje de carga, materiales de EPP (equipos de protección personal) y en la distribución de alimentos.",
          message2:
            "Para la empresa, el resultado es siempre la mejor forma de mostrar su competencia. Es aquella que, con la práctica, nos sirve con precisión. Percibimos el poder de nuestra innovación en el día a día de las empresas del grupo. Las inversiones en la prestación de servicios y comercialización de insumos industriales son nuestros puntos fuertes.",
        },
      },
      about_us: {
        title: "DEPOSICIONES",
        message:
          "Los avances tecnológicos y el conocimiento nos demuestran que somos competentes en lo que entregamos, buscando la calidad y la perfección. Existimos para asegurar la satisfacción de nuestros clientes y buscamos invertir en la formación y valoración de nuestros empleados. Queremos hacer la vida de las personas más fácil, con aptitud y plenitud.",
        depoiments: {
          depoiment1:
            "José Luiz Matos trabaja desde hace 11 años en una de las empresas que componen el grupo Bergson, como desarrollador de proyectos eléctricos y de automatización. “Es con satisfacción y con amor que intento dar lo mejor de mí dentro del grupo, veo mi trabajo como una oportunidad de crecer… Para mí es un honor pertenecer a este grupo que es Bergson Cooportarion”.",
        },
        mission: {
          title: "MISIÓN",
          text: "Nuestra misión es liderar la innovación en nuestra industria, desarrollando soluciones innovadoras que resuelvan los desafíos de nuestros clientes y la sociedad. Creemos en el trabajo colaborativo y creativo, buscando siempre formas de mejorar nuestros productos, servicios y procesos. Nos esforzamos por ser una empresa responsable y ética que contribuya al desarrollo sostenible de la sociedad.",
        },
        vision: {
          title: "VISIÓN",
          text: "Nuestra visión es ser reconocidos como líder en nuestra industria, ofreciendo productos y servicios excepcionales que superen las expectativas de nuestros clientes y contribuyan al bienestar de la sociedad y del planeta. Queremos ser una empresa respetada y admirada por nuestros empleados, socios y comunidades, y queremos trabajar de manera ética y responsable para lograr nuestros objetivos a largo plazo.",
        },
        values: {
          title: "VALORES",
          text: "Respeto, Transparencia, Ética, Responsabilidad Social, Compromiso e Innovación.",
        },
      },
      contact: {
        name: "Nombre",
        cellphone: "Número de teléfono",
        company: "Nombre de la empresa",
        header: "Asunto del mensaje",
        body: "Mensaje",
        submit: "MANDAR",
        address: "Dirección comercial",
        phone: "Teléfono",
        email: "Envíanos un correo electrónico",
      },
      our_companies: {
        industrial_solution:
          "Soluções Industrial, fue fundada en 2008 en la ciudad de Belo Horizonte, tiene 15 años de experiencia en el mercado de mantenimiento de equipos de carga y en el crecimiento de proyectos técnicos para centrales hidroeléctricas, ya sean térmicas o nucleares.",
        bergson_brasil:
          "Bergson do Brasil, fundada en 1999 en la ciudad de Belo Horizonte, actúa en el mercado de fabricación de equipos de elevación de carga hace 24 años. Con la creación de proyectos técnicos para centrales hidroeléctricas, térmicas y nucleares, industrias cementeras, azucareras y petroleras, entre muchos otros segmentos.",
        see_more: "Ver más sobre ->",
      },
      whatsapp: {
        chatMessage: "¡Hola! ¿Como te puedo ayudar?",
      },
      modal: {
        title: "¡Gracias!",
        message:
          "Su mensaje ha sido enviado y en breve nos pondremos en contacto con usted!",
      },
      footer: { networks: "Descubre nuestras redes." },
    },
  },
};

export { messages };
