import styled from "styled-components";
import { i18n } from "../translate/i18n";

import bergson_logo from "../images/bergson-corp-azul.png";
import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";

export default function Footer() {
  return (
    <Container>
      <Content>
        <FooterMenu>
          <img className="none" src={bergson_logo} alt="logo-sci" />
          <SocialMedia>
            <p>{i18n.t("footer.networks")}</p>
            <div>
              {/* <a
                href="https://www.instagram.com/sciminasgerais/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="icon" />
              </a> */}
              {/* <a
                href="https://www.facebook.com/SciMinasGerais"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook className="icon" />
              </a> */}
              <a
                href="https://www.linkedin.com/company/bergsoncorporation/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=br"
                target="_blank"
                rel="noreferrer"
              >
                <BsLinkedin className="icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCMFR2bw2GhTQr8ySI-2xJ7A"
                target="_blank"
                rel="noreferrer"
              >
                <BsYoutube className="icon" />
              </a>
              {/* <a
                href="https://twitter.com/scibrasil"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter className="icon" />
              </a> */}
            </div>
          </SocialMedia>
        </FooterMenu>
        <p>&copy; Copyright 2023 Bergson Corporation</p>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 50px 0 20px 0;
  background-color: #8bc0ea;

  @media (max-width: 450px) {
    padding: 20px 0 20px 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1100px;

  p {
    color: #040830;
    text-align: center;
  }

  img {
    width: 400px;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 20px;
  }

  @media (max-width: 450px) {
    padding: 0 10px;

    div {
      font-size: 14px;
    }

    img {
      width: 100px;
      height: 35px;
    }

    .none {
      display: none;
    }
  }
`;

const FooterMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #040830;
  margin-bottom: 10px;

  .icon {
    font-size: 25px;
    color: #040830;
    margin-top: 10px;
  }

  .pointer {
    cursor: pointer;
  }

  @media (max-width: 450px) {
    justify-content: center;
  }
`;

const SocialMedia = styled.div`
  div {
    width: 100%;
    display: flex;
    justify-content: center;

    a {
      margin-right: 10px;
    }
  }
`;
