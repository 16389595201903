import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import furnas from "../images/furnas.png";
import itaipu from "../images/itaipu.png";
import eletronorte from "../images/eletronorte.png";
import emae from "../images/emae.jpg";
import cemig from "../images/cemig.png";

const CompanyBannerContainer = styled.div`
  width: auto;
  overflow: hidden;

  @media (max-width: 450px) {
    margin: 0 auto;
    width: 350px;
  }
`;

const CompanyLogo = styled.div`
  width: 100% !important;
  height: 150px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  img {
    height: auto;
    width: 150px;
  }

  @media (max-width: 450px) {
    img {
      width: 100px;
    }
  }
`;

const CompanyBanner = () => {
  const logos = [furnas, itaipu, eletronorte, emae, cemig];
  const centeredSlideIndex = Math.floor(logos.length / 2);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: centeredSlideIndex, // Começa com um offset para a esquerda
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <CompanyBannerContainer>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <CompanyLogo key={index}>
            <img src={logo} alt={`Company Logo ${index + 1}`} />
          </CompanyLogo>
        ))}
      </Slider>
    </CompanyBannerContainer>
  );
};

export default CompanyBanner;
