import styled from "styled-components";
import LanguageSelector from "./LanguageSelector";

import back from "../images/corporate-building.jpg";
import logo from "../images/bergson-corp.png";

export default function TopLogo() {
  return (
    <>
      <ContainerImage>
        <LanguageSelector />
        <img className="primary-image" src={back} alt="BackgroundImage" />
        <img className="secondary-image" src={logo} alt="logo" />
      </ContainerImage>
    </>
  );
}

const ContainerImage = styled.div`
  display: inline-block;
  width: 100%;
  background-image: linear-gradient(0deg, black);
  position: relative;

  .primary-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    -webkit-mask-image: linear-gradient(to top, transparent 25%, black 100%);

    @media (max-width: 800px) {
      height: 400px;
    }

    @media (max-width: 450px) {
      height: 300px;
    }
  }

  .secondary-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    width: 600px;

    @media (max-width: 800px) {
      width: 500px;
    }

    @media (max-width: 450px) {
      width: 300px;
    }
  }
`;
