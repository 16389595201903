import React from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { i18n } from "../translate/i18n";

import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { MdPhoneIphone } from "react-icons/md";
import MapModal from "./MapModal";

import SuccessModal from "./SuccessModal";

export default function Contact() {
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showMapModal, setShowMapModal] = React.useState(false);
  const [showNumber, setShowNumber] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(false);

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    cell: "",
    company: "",
    messageHeader: "",
    messageBody: "",
  });

  const handleClickShowNumber = () => {
    setShowNumber(!showNumber);
  };

  const handleClickShowEmail = () => {
    setShowEmail(!showEmail);
  };

  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_41nvis5",
        "template_59z6ixl",
        e.target,
        "Zo5kB3y-AyNzDvOQS"
      )
      .then((e) => {
        setShowSuccessModal(true);
        setFormData({
          name: "",
          email: "",
          cell: "",
          company: "",
          messageHeader: "",
          messageBody: "",
        });
      });
  }

  return (
    <>
      <StyledForm onSubmit={sendEmail}>
        <StyledLabel htmlFor="name">
          <p>{i18n.t("contact.name")}:</p>
        </StyledLabel>
        <StyledInput
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />

        <StyledLabel htmlFor="email">
          <p>Email:</p>
        </StyledLabel>
        <StyledInput
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
        />

        <StyledLabel htmlFor="cell">
          <p>{i18n.t("contact.cellphone")}:</p>
        </StyledLabel>
        <StyledInput
          type="tel"
          id="cell"
          name="cell"
          value={formData.cell}
          onChange={(e) => setFormData({ ...formData, cell: e.target.value })}
          required
        />

        <StyledLabel htmlFor="company">
          <p>{i18n.t("contact.company")}:</p>
        </StyledLabel>
        <StyledInput
          type="text"
          id="company"
          name="company"
          value={formData.company}
          onChange={(e) =>
            setFormData({ ...formData, company: e.target.value })
          }
          required
        />

        <StyledLabel htmlFor="message-header">
          <p>{i18n.t("contact.header")}:</p>
        </StyledLabel>
        <StyledInput
          type="text"
          id="message-header"
          name="message-header"
          value={formData.messageHeader}
          onChange={(e) =>
            setFormData({ ...formData, messageHeader: e.target.value })
          }
          required
        />
        <StyledLabel htmlFor="message-body">
          <p>{i18n.t("contact.body")}:</p>
        </StyledLabel>
        <StyledTextarea
          id="message-body"
          name="message-body"
          value={formData.messageBody}
          onChange={(e) =>
            setFormData({ ...formData, messageBody: e.target.value })
          }
          required
        ></StyledTextarea>

        <StyledButton type="submit">
          <h1>{i18n.t("contact.submit")}</h1>
        </StyledButton>
        <SuccessModal
          isOpen={showSuccessModal}
          onClose={handleCloseSuccessModal}
        />
      </StyledForm>
      <ContactArea>
        <div>
          <MdLocationOn
            className="icon pointer"
            onClick={() => setShowMapModal(true)}
          />
          <p>{i18n.t("contact.address")}</p>
        </div>
        <div className="position">
          <MdPhoneIphone
            className="icon pointer"
            onClick={handleClickShowNumber}
          />
          <p>{i18n.t("contact.phone")}</p>
          {showNumber && (
            <InfoBox1>
              <InfoText>(31) 3571-1370</InfoText>
            </InfoBox1>
          )}
        </div>
        <div className="position">
          <MdEmail className="icon pointer" onClick={handleClickShowEmail} />
          <p>{i18n.t("contact.email")}</p>
          {showEmail && (
            <InfoBox2>
              <InfoText>administrativo@bergsoncorporation.com.br</InfoText>
            </InfoBox2>
          )}
        </div>
      </ContactArea>
      <MapModal isOpen={showMapModal} onClose={handleCloseMapModal} />
    </>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
  color: #fff;
  margin-bottom: 100px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
`;

const StyledInput = styled.input`
  border: 1px solid #fff;
  padding: 0.5rem 0.5rem;
  font-size: 16px;
  border-radius: 50px;

  &:focus {
    outline: none;
    border-bottom: 2px solid #fff;
    background-color: #ffffff !important;
  }

  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

const StyledTextarea = styled.textarea`
  border: 1px solid #fff;
  padding: 0.5rem 0.5rem;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  border-radius: 10px;

  &:focus {
    outline: none;
    border: 2px solid #fff;
  }

  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #000;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
  }
`;

const ContactArea = styled.div`
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin: 30px auto 100px auto;

  .icon {
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  p {
    color: #ffffff;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pointer {
    cursor: pointer;
  }

  .position {
    position: relative;
  }

  @media (max-width: 450px) {
    width: 100%;

    div {
      width: 100px;
      text-align: center;
    }
  }
`;

const InfoBox1 = styled.div`
  min-width: 170px;
  position: absolute;
  bottom: 50%;
  left: 80%;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;

  @media (max-width: 450px) {
    left: -140px;
  }
`;

const InfoBox2 = styled.div`
  min-width: 260px;
  position: absolute;
  bottom: 50%;
  left: 80%;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;

  @media (max-width: 450px) {
    left: -230px;
  }
`;

const InfoText = styled.span`
  font-size: 14px;
`;
