import styled from "styled-components";
import { i18n } from "../translate/i18n";

import brasil_logo from "../images/bergson-do-brasil-logo.png";
import solucao_logo from "../images/solucao-industrial-logo.png";
import construcao_logo from "../images/solucao-construcao-logo.png";

export default function OurCompanies() {
  return (
    <Container>
      <Companies>
        <Box>
          <img src={solucao_logo} alt="solução industrial" />
        </Box>
        <Box>
          <p>{i18n.t("our_companies.industrial_solution")}</p>
          <div>
            <a href="https://www.google.com" target="_blank" rel="noreferrer">
              {i18n.t("our_companies.see_more")}
            </a>
          </div>
        </Box>
      </Companies>
      <Companies>
        <Box>
          <p>{i18n.t("our_companies.bergson_brasil")}</p>
          <div>
            <a href="https://www.google.com" target="_blank" rel="noreferrer">
              {i18n.t("our_companies.see_more")}
            </a>
          </div>
        </Box>
        <Box>
          <img src={brasil_logo} alt="bergson do brasil" />
        </Box>
      </Companies>
      <Companies>
        <Box>
          <img src={construcao_logo} alt="solução construção" />
        </Box>
        <Box>
          <p>{i18n.t("our_companies.industrial_solution")}</p>
        </Box>
      </Companies>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 60px;
`;

const Companies = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 30px;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  width: 100%;
  padding: 20px;

  p {
    margin-bottom: 15px;
  }

  div {
    width: 100%;
    text-align: right;
  }

  img {
    object-fit: cover;
    width: 100%;
  }
`;
