import Menu from "./Menu";
import TopLogo from "./TopLogo";
import WhatsApp from "./WhatsApp";
import LanguageSelector from "./LanguageSelector";
import Footer from "./Footer";

export default function Main() {
  return (
    <>
      <LanguageSelector />
      <TopLogo />
      <Menu />
      <Footer />
      <WhatsApp />
    </>
  );
}
