import styled from "styled-components";
import React from "react";
import { i18n } from "../translate/i18n";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import img2 from "../images/african-american-male.jpg";

export default function AboutUs() {
  const imgStyle = {
    maxWidth: "350px",
    borderRadius: "10px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.5)",
    transition: "box-shadow 0.3s ease-in-out",
  };

  const textBoxStyle = {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#fff",
    textAlign: "justify",
    lineHeight: "1.5",
    padding: "25px",
  };

  const InitialImageText = styled.div`
    margin: 80px 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 450px) {
      margin-top: 0;
      flex-direction: column;
      margin-bottom: 20px;
    }
  `;

  return (
    <>
      <Box>
        <InitialImageText>
          <div style={textBoxStyle}>
            <p>{i18n.t("about_us.message")}</p>
          </div>
          <img src={img2} alt="male" style={imgStyle} />
        </InitialImageText>
        <Tabs>
          <TabList>
            <Tab>
              <h1>{i18n.t("about_us.mission.title")}</h1>{" "}
              <span className="dot"></span>
            </Tab>
            <Tab>
              <h1>{i18n.t("about_us.values.title")}</h1>{" "}
              <span className="dot"></span>
            </Tab>
            <Tab>
              <h1>{i18n.t("about_us.vision.title")}</h1>{" "}
              <span className="dot"></span>
            </Tab>
          </TabList>

          <TabPanel>
            <p>{i18n.t("about_us.mission.text")}"</p>
          </TabPanel>
          <TabPanel>
            <p>{i18n.t("about_us.values.text")}</p>
          </TabPanel>
          <TabPanel>
            <p>{i18n.t("about_us.vision.text")}</p>
          </TabPanel>
        </Tabs>

        <Title>
          <h1>{i18n.t("about_us.title")}</h1>
        </Title>
        <Tabs>
          <TabList>
            <Tab>
              <span className="dot"></span>
            </Tab>
            <Tab>
              <span className="dot"></span>
            </Tab>
            <Tab>
              <span className="dot"></span>
            </Tab>
          </TabList>

          <TabPanel>
            <p>{i18n.t("about_us.depoiments.depoiment1")}"</p>
          </TabPanel>
          <TabPanel>
            <p>{i18n.t("about_us.depoiments.depoiment1")}</p>
          </TabPanel>
          <TabPanel>
            <p>{i18n.t("about_us.depoiments.depoiment1")}</p>
          </TabPanel>
        </Tabs>
      </Box>
    </>
  );
}

const Title = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const Box = styled.div`
  width: 100%;
  margin-bottom: 50px;

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      display: inline-block;
    }
  }

  .react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 0 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-tabs__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2;
    text-align: center;
    border: 1px solid transparent;
    border-bottom: none;
    list-style: none;
    padding: 6px 7px;
    cursor: pointer;
  }

  .react-tabs__tab--selected {
    background: transparent;
    border-color: none;

    h1 {
      color: #040830;
    }

    .dot {
      background-color: #040830;
    }
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab-panel {
    display: none;
    background: #040830;
    width: auto;
    height: 150px;
    padding: 20px;
    border-radius: 7px;
    margin-bottom: 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);

    @media (max-width: 800px) {
      padding: 15px;
      height: 100%;
    }
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  @media (max-width: 600px) {
    .react-tabs__tab-list {
      overflow-y: auto;
      width: 100%;
      white-space: nowrap;
    }
  }

  p {
    text-align: justify;
    color: #fff;
  }
`;
