import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MainMenu from "./MainMenu";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import OurCompanies from "./OurCompanies";
import { i18n } from "../translate/i18n";
import "../styles/tab-menu.css";

export default function Menu() {
  return (
    <Container>
      <Content>
        <Tabs>
          <TabList>
            <Tab>
              <h1>{i18n.t("menu_titles.main_menu")}</h1>
            </Tab>
            <Tab>
              <h1>{i18n.t("menu_titles.about_us")}</h1>
            </Tab>
            <Tab>
              <h1>{i18n.t("menu_titles.contact")}</h1>
            </Tab>
            {/* <Tab>
              <h1>{i18n.t("menu_titles.our_companies")}</h1>
            </Tab> */}
          </TabList>
          <TabPanel>
            <MainMenu />
          </TabPanel>
          <TabPanel>
            <AboutUs />
          </TabPanel>
          <TabPanel>
            <Contact />
          </TabPanel>
          {/* <TabPanel>
            <OurCompanies />
          </TabPanel> */}
        </Tabs>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(83, 122, 144, 1) 100%
  );
`;

const Content = styled.div`
  width: 1100px;

  @media (max-width: 450px) {
    height: 100%;
    padding: 20px;
  }
`;
